.macs-blue {
    color: $macs-blue !important;
}

.bg-macs-blue {
    background-color: $macs-blue !important;
}

.bg-white {
    background-color: $white !important;
}