@font-face {
    font-family: "Lato";
    src: url("/dist/fonts/Lato-Black.woff2") format("woff2"),
         url("/dist/fonts/Lato-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("/dist/fonts/Lato-Bold.woff2") format("woff2"),
         url("/dist/fonts/Lato-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("/dist/fonts/Lato-Light.woff2") format("woff2"),
         url("/dist/fonts/Lato-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("/dist/fonts/Lato-Regular.woff2") format("woff2"),
         url("/dist/fonts/Lato-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Oswald";
    src: url("/dist/fonts/Oswald-Light.woff2") format("woff2"),
         url("/dist/fonts/Oswald-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Oswald";
    src: url("/dist/fonts/Oswald-Bold.woff2") format("woff2"),
         url("/dist/fonts/Oswald-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Oswald";
    src: url("/dist/fonts/Oswald-SemiBold.woff2") format("woff2"),
         url("/dist/fonts/Oswald-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Oswald";
    src: url("/dist/fonts/Oswald-Medium.woff2") format("woff2"),
         url("/dist/fonts/Oswald-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Oswald";
    src: url("/dist/fonts/Oswald-ExtraLight.woff2") format("woff2"),
         url("/dist/fonts/Oswald-ExtraLight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Oswald";
    src: url("/dist/fonts/Oswald-Regular.woff2") format("woff2"),
         url("/dist/fonts/Oswald-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FontAwesome";
    src: url("/dist/fonts/fontawesome/fa-brands-400.woff2") format("woff2"),
         url("/dist/fonts/fontawesome/fa-brands-400.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    font-size: xx-small;
}

@font-face {
    font-family: "FontAwesome";
    src: url("/dist/fonts/fontawesome/fa-regular-400.woff2") format("woff2"),
         url("/dist/fonts/fontawesome/fa-regular-400.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    font-size: xx-small;
}

@font-face {
    font-family: "FontAwesome";
    src: url("/dist/fonts/fontawesome/fa-solid-900.woff2") format("woff2"),
         url("/dist/fonts/fontawesome/fa-solid-900.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    font-size: xx-small;
}

@media (min-width: 0) {
    body {
        color: $macs-navy;
        font-family: 'Lato', Arial, sans-serif;
        font-size: $base-font-size;
        font-weight: normal;
        line-height: 20px;
    }

    h1, .h1 {
        color: #fff;
        font-family: 'Oswald', 'Arial Narrow', 'Helvetica Neue', sans-serif !important;
        font-size: 3.625rem;
        font-weight: bold;
        letter-spacing: 1.33px;
        line-height: 60px;
        margin-bottom: 15px;
        text-transform: uppercase;
    }
    h2, .h2 {
        font-family: 'Oswald', 'Arial Narrow', 'Helvetica Neue', sans-serif !important;
        opacity: 1;
    }
    h3, .h3 {
        display: block;
        font-family: 'Oswald', 'Arial Narrow', 'Helvetica Neue', sans-serif !important;
        font-size: 1rem;
    }
    h4, .h4 {opacity: 1;}
    h5, .h5 {opacity: 1;}

    p {opacity: 1;}

    a {
        color: $macs-blue;
        font-weight: bold;
        text-decoration: none;
    }

    .font-family-lato {
        font-family: 'Lato', Arial, sans-serif;
    }

    .font-family-oswald {
        font-family: 'Oswald', 'Arial Narrow', 'Helvetica Neue', sans-serif;
    }

    .font-size-14 {
        font-size: .875rem;
    }

    .font-weight-bold {
        font-weight: bold;
    }
}


@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {
    h3, .h3 {
        font-size: 1.375rem;
    }
}

@media (min-width: 1024px) {

}

@media (min-width: 1200px) {
    h3, .h3 {
        font-size: 1.562rem;
    }
}

@media (min-width: 1920px) {

}