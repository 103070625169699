@media (min-width: 0) {
    .banner {
        position: relative;
        width: 100%;
    
        &__close {
            background: url('/dist/images/close.svg') no-repeat;
            background-position: center center;
            box-sizing: content-box;
            cursor: pointer;
            height: 15px;
            padding: 5px;
            width: 15px;
        }
    
        &__container {
            position: relative;
        }
        
        &__content {
            &--alert-banner {
                margin: 0 auto 0 0;
    
                p {
                    margin: 10px 0;
                }
            }
        }
    
        &__home-slide {
            background-size: cover;
            height: 350px;
        }

        &__notify {
            align-items: center;
            background-color: $macs-yellow;
            display: flex;
            flex-direction: row;
            padding: 0 10px;
    
            div {
                a {
                    color: #000;
                    text-decoration: underline;
    
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        
        &__strapline {
            text-shadow: 0 1px 4px rgba(0, 0, 0, .5);
            font-size: 1.562rem;
            font-weight: bold;
            color: #fff;
            margin-bottom: 40px;
        }
    }
}

@media (min-width: 576px) {
    .banner {
        &__content {
            &--alert-banner {
                margin: 0 auto;
            }
        }
    }
}
@media (min-width: 992px) {
    .banner {
        &__home-slide {
            height: 400px;
        }
    }
}

@media (min-width: 1200px) {
    .banner {
        &__home-slide {
            height: 500px;
        }
    }
}



@media (min-width: 1400px) {
    .banner {
        &__home-slide {
            height: 650px;
        }
    }
}
