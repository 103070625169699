@media (min-width: 0) {
    body {
        padding-top: 0;
    }

    .content-container {
        padding: 0 20px;
        width: 100%;
    }

    .ma-img {
        height: auto;
        width: 100%;
    }

    .macs-align-right {
        text-align: right !important;
    }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
    .content-container {
        padding: 0 50px;
    }
}

@media (min-width: 992px) {
    body {
        padding-top: 110px;
    }

    .content-container {
        padding: 0 80px;
    }
}

@media (min-width: 1200px) {
    body {
        padding-top: 115px;
    }
}

@media (min-width: 1920px) {

}