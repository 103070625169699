@media (min-width: 0) {
    .b-corp-logo {
        height: auto;
        max-width: 40px;
        width: 100%;
    }
}

@media (min-width: 992px) {
    .b-corp-logo {
        max-width: 50px;
    }
}