@media (min-width: 0) {
    .header {
        border-bottom: 1px solid $macs-grey-faded;
        padding: 0;
    }
    
    .login {
        align-items: center;
        border-bottom: 1px solid $macs-grey-faded;
        display: flex;
        font-size: 0.75rem;
        font-weight: bold;
        justify-content: space-between;
        margin: 0;
        padding: 20px;
    
        &__link {
            color: $macs-navy;
            display: inline-block;
            text-decoration: none;
        }
    
        &__pipe {
            font-size: 1rem;
            font-weight: normal;
        }
    
        &__tel-icon {
            display: block;
        }
    }

    .navigation-container {
        align-items: center;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;

        &--border-bottom {
            border-bottom: 1px solid $macs-grey-light;
        }
    }

    .stick-top {
        left: 0;  
        position: fixed !important;
        right: 0;
        top: 0;
        z-index: 1000;
    }

    .nav-btn {
        background-color: transparent;
        border: none;
        padding: 0;
        position: relative;

        &--burger {
            display: block;
        }

        &--close {
            &::after {
                content: 'Close';
                color: $macs-blue;
                font-size: .625rem;
                font-weight: bold;
                left: 0;
                position: absolute;
                right: 0;
                top: 25px;
            }
        }
    }

    .img__logo {
        margin: 10px 0;
        max-width: 160px;
    }

    .menu-bar {
        background-color: #fff;
        height: 100vh;
        left: -300px;
        overflow: hidden;
        position: fixed;
        top: 0;
        transition: left .5s;
        width: 300px;
        z-index: 1500;
    }

    .expanded {
        box-shadow: -4px 0 10px rgba(0, 0, 0, .3);
        left: 0;
        overflow-y: auto;
    }

    .close-container {
        border-bottom: 1px solid $macs-grey;
        display: block;
        padding: 20px;
        text-align: right;
    }

    .nav-links {
        display: flex;
        justify-content: space-between;
        letter-spacing: .4px;
        flex-direction: column;
        font-family: 'Oswald', 'Arial Narrow', 'Helvetica Neue', sans-serif;
        font-size: 18px;
        font-weight: 500;
        margin: 0 auto;
        padding: 0;
        max-width: 650px;
        width: 100%; 
    
        &__nav-item {
            border-bottom: 1px solid $macs-grey;
            cursor: pointer;
            display: block;
            color: $macs-navy;
            font-weight: 500;
            line-height: 1.65;
            padding: 20px;
    
            &--arrow::after {
                content: url('/dist/images/nav-dd-icon.svg');
                left: 250px;
                margin-left: 13px;
                position: absolute;
                rotate: 270deg;
                top: unset;
            } 
        }

        &__primary-li {
            list-style: none;
        }
    }
    
    .nav-links .show a {
        border-bottom: 3px solid $macs-blue;
    }
    
    .mega-menu {
        bottom: 0;
        background-color: #fff;
        box-shadow: none;
        display: none;
        left: 305px;
        margin: 0;
        position: absolute;
        right: unset;
        top: 69px;
        transition: left 0.2s linear;
        width: 100%;
        z-index: 10;

        &--block {
            display: block !important;
        }

        &--flex {
            display: flex !important;
        }

        &__link {
            border-bottom: 1px solid $macs-grey;
            color: $macs-navy;
            display: block;
            font-size: 1.125rem;
            font-weight: 500;
            line-height: 1.65;
            list-style: none;
            padding: 20px;

            &--arrow::after {
                content: url('/dist/images/nav-dd-icon.svg');
                left: 250px;
                margin-left: 13px;
                position: absolute;
                rotate: 270deg;
                top: unset;
            }

            &--header {
                padding: 30px 20px;

                a {
                    color: $macs-navy;
                    cursor: pointer;
                    font-weight: 500;
                    // transition: color 0.1s linear;

                    &:hover {
                        color: $macs-blue;
                    }
                }
            }

            &--footer {
                border: none;
                padding: 30px;
                text-decoration: underline;

                &:hover {
                    color: $macs-navy;
                    text-decoration: none;
                }
            }

            &--back {
                color: $macs-blue;
                cursor: pointer;
                display: block;
                padding-left: 50px;
                position: relative;

                &:before {
                    content: url('/dist/images/menu-arrow.svg');
                    left: 20px;   
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &--secondary {
                list-style: none;
            }
        }

        &__grid {
            display: none;
            position: relative;
            width: 100%;
        }

        &__secondary-menu {
            width: 100%;

            ul {
                margin:0; 
                padding: 0;
            }
        }

        &__tertiary {
            background-color: #fff;
            bottom: 0;
            display: none;
            left: 305px;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 10;
        }

        &__tertiary-list {
            padding: 0;
            width: 100%;

            &--second-col {
                background-color: transparent;
            }

            &--feature {
                display: none;
            }

            h3 {
                color: $macs-navy;
                font-size: 1.125rem;
                font-weight: 500;
                margin-bottom: 0.625rem;
            }
        }

        &__mobile-container {
            border-bottom: 1px solid $macs-grey;
            margin-bottom: 0;
            padding: 30px;
        }

        &__tertiary-ul {
            margin-bottom: 0;

            li {
                line-height: 17px;
                list-style: none;
                padding-bottom: 4px;
    
                a {
                    color: $macs-navy;
                    font-family: 'Lato', Arial, sans-serif;
                    font-size: 0.875rem;
                    font-weight: normal;
                }
            }
        }
    }

    /*.mega-menu__link--secondary:hover > .mega-menu__tertiary {
        display: block;
    }*/

    .contact {
        bottom: 0; 
        color: $macs-blue;
        display: flex;
        line-height: 19px; 
        margin: auto 0;
        padding-top: 0;
        right: 15px;  
        text-align: right;
        top: 0; 
        z-index: 1040;

        &--fixed {
            position: fixed;
            top: -600px;
        }

        &__time {
            display: none;
            font-size: 12px;
            line-height: 15px;
        }
    
        &__tel-number {
            display: none;
            font-weight: bold;
        }
    
        &__favourites {
            align-items: center;
            display: flex;
            margin-left: 0;
        }
    
        &__favourites-icon {
            height: 34px;
            width: 34px;
        }
    }
}

@media (min-width: 992px) {
    .header {
        padding: 10px 12px 2px;
    }
    
    .login {
        border-bottom: none;
        justify-content: end;
        margin: 0 0 6px;
        padding: 0;
    
        &__link {
            color: $macs-navy;
            display: inline-block;
            padding-top: 0;
            text-decoration: none;
        }

        &__tel-icon {
            display: none;
        }
    }

    .navigation-container {
        display: flex;
        padding: 0;
    }

    .nav-btn {
        &--burger {
            display: none;
        }
    }

    .img__logo {
        margin: -32px 0 0;
        max-width: 180px;
    }

    .menu-bar {
        box-shadow: none !important;
        flex-grow: 1;
        height: auto;
        left: unset;
        overflow: visible;
        position: static;
        width: auto;
        top: unset;
    }

    .close-container {
        display: none;
    }

    .nav-links {
        flex-direction: row;
        font-size: 12px;
        max-width: 450px;
        text-wrap: nowrap;

        &__primary-li {
            border-bottom: 3px solid #fff;

            &:hover {
                border-bottom: 3px solid $macs-blue;
            }
        }

        &__nav-item {
            border-bottom: 3px solid #fff;
            display: inline-block;
            padding: 15px 0 30px 0;

            /*&:hover {
                border-bottom: 3px solid $macs-blue;
            }*/
    
            &--arrow::after {
                margin-left: 8px;
                left: unset;
                position: relative;
                top: -2px;
            } 
        }
    }

    .mega-menu {
        border: 1px solid $macs-grey-faded;
        bottom: unset;
        box-shadow: -1px 9px 7px 0 rgba(0, 0, 0, .36);
        left: 0 !important;
        margin: 3px 50px 0;
        max-height: 550px;
        height: 70vh;
        opacity: 0;
        right: 0;
        transition: opacity 0.2s linear;
        top: unset;
        white-space: normal;
        width: unset;

        img {
            height: auto;
            width: 100%;
        }

        &__content {
            display: flex;
            flex-grow: 1;
        }

        &__link {
            padding: 10px 30px;
            position: relative;

            &--arrow {
                &::after {
                    content: none;
                }

                &:hover {  
                    color: $macs-blue;
                    text-decoration: none;

                    &::before {
                        content: url(/dist/images/menu-arrow.svg);
                        margin-left: 0;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(50%);
                        rotate: 180deg;
                    }
                }
            }

            &--back {
                display: none;
            }

            &--header {
                padding: 30px;
            }

            &--secondary {
                &:hover > .mega-menu__tertiary {
                    display: flex;
                }
            }
        }

        &__grid {
            background-color: #f4f5f6;
            bottom: 0;
            column-gap: 10px;
            display: grid;
            grid-auto-rows: min-content;
            grid-template-columns: repeat(5, 1fr);
            padding: 30px;
            row-gap: 10px;
            top: 0;           
            width: 75%;
        }

        &__grid-item {
            position: relative;

            span {
                bottom: 10px;
                color: #fff;
                font-family: 'Lato', Arial, sans-serif;
                font-size: 1rem;
                font-weight: bold;
                left: 10px;
                position: absolute;
                right: 10px;
            }
        }

        &__secondary-menu {
            width: 25%;
        }

        &__tertiary {
            background-color: #fff;
            border-left: 1px solid rgba(151,151,151,.25);
            justify-content: flex-start;
            left: 25% !important;
            width: 75%;

            &--about {
                display: flex;
            }
        }

        &__tertiary-list {
            overflow: auto;
            padding: 20px;
            scrollbar-width: thin;
            scrollbar-color: #7F7F7F #B6B6B6;
            width: 33.33%;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #7F7F7F;
                border-radius: 6px;
            }

            &::-webkit-scrollbar-track {
                background-color: #B6B6B6; 
                border-radius: 10px;
            }

            &--second-col {
                background-color: $macs-grey-light;
            }

            &--feature {
                background-color: $macs-blue;
                display: block;
                padding: 0;
            }

            h3 {
                color: $macs-navy;
                font-size: 1.25rem;
                margin-bottom: 0.625rem;
            }
        }

        &__mobile-container {
            border-bottom: none;
            margin-bottom: 24px;
            padding: 0;
        }

        &__tertiary-ul {
            a {
                &:hover {
                    color: $macs-blue;
                    text-decoration: none;
                }
            }

        }

        &__feature-content {
            color: #fff;
            padding: 30px;

            h5 {
                font-size: 20px;
                margin-bottom: 5px;
            }

            p {
                font-family: 'Lato', Arial, sans-serif;
                font-size: 0.875rem;
                font-weight: normal;
                margin-bottom: 35px;
            }
        }

    }

    .contact {   
        position: relative;
        right: unset;
        
        &__time {
            display: inline-block;
        }

        &__tel-number {
            display: block;
        }

        &__favourites {
            margin-left: 15px;
        }

        &__favourites-icon {
            height: 35px;
            width: 35px;
        }
    }
}

@media (min-width: 1200px) {    
    .header {
        padding: 10px 25px 2px;
    }
    
    .img__logo {
        max-width: 210px;
    }
    
    .nav-links {
        font-size: 16px;
        max-width: 610px;

        &__nav-item {
    
            &--arrow::after {
                margin-left: 13px;
                top: -4px;
            } 
        }
    }

    .mega-menu {
        margin: 3px 75px 0;

        &__grid {          
            width: 70%;
        }

        &__secondary-menu {
            width: 30%;
        }

        &__tertiary {
            left: 30% !important;
            width: 70%;
        }
    }

    .contact {  
        &__time {
            font-size: 14px;
            line-height: 19px;
        }

        &__favourites-icon {
            height: 44px;
            width: 44px;
        }
    }
}

@media (min-width: 1400px) {    
    .mega-menu {
        margin: 3px auto 0;
        max-width: 1365px;
    }
}

@media (min-width: 1600px) {    
    .nav-links {
        font-size: 18px;
        max-width: 780px;
    }
}