@media (min-width: 0) {
    .footer-nav {
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 1740px;
        padding: 0;
        width: 100%;
    }
    
    .nav-group {
        border-bottom: 1px solid $macs-grey-faded;
        padding: 30px 20px;
        
        &__radio {
            display: none;

            &:checked ~ .nav-group__label::after {
                content: url('../images/ft-minus.svg');
            }

            &--h-auto:checked ~ .nav-group__columns {
                height: auto;
            }

            &--h-210:checked ~ .nav-group__columns {
                height: 210px;
            }

            &--h-270:checked ~ .nav-group__columns {
                height: 270px;
            }

            &--h-400:checked ~ .nav-group__columns {
                height: 400px;
            }
        }

        &__label {
            cursor: pointer;
            margin-bottom: 0;
            text-transform: uppercase;
            white-space: nowrap;

            &::after {
                content: url('../images/ft-plus.svg');
                margin-left: 13px;
                position: absolute;
                right: 40px;
            }
        }

        &__columns {
            height: 0;
            margin: 0;
            overflow: hidden;
            padding: 0;
            transition: height 0.5s;
        }

        &__footer-link {
            font-size: 0.812rem;
            list-style: none;
            margin-bottom: 12px;

            &:first-of-type {
                margin-top: 25px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            a {
                color: $macs-navy;
                font-weight: normal;
                transition: color .3s;

                &:hover {
                    color: $macs-blue;
                    transition: color .3s;
                }
            }
        }
    }

    .affiliates {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 20px 0 30px;
        padding: 15px 0 0;
        text-align: center;

        &__affiliate-cell {
            &--feefo {
                margin-bottom: 30px;
                width: 100%;
            }
            &--change-country {
                font-size: .875rem;
                width: 50%;
            }
            &--accepted-cards {
                width: 50%;
            }
            &--feefo-name {
                color: $macs-grey-dark;
                font-size: 1.187rem;
                font-weight: bold;
            }

            &--feefo-count {
                color: #000;
                font-size: 28px;
                letter-spacing: -1px;
                line-height: 28px;
                vertical-align: middle;

                span {
                    font-weight: bold;
                }
            }

            &--feefo-rating {
                color: #505b67;
                font-size: 11px;
                line-height: 14px;
                margin-top: 8px;

                span {
                    font-weight: bold;
                }
            }
        }

        &__affiliate-label {
            font-family: 'Oswald', 'Arial Narrow', 'Helvetica Neue', sans-serif;
            font-size: .875rem;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }

    .star-rating__i {
        margin-right: -3px;

        &:last-of-type {
            margin-right: 10px;
        }
    }

    .feefo-logo {
        margin-left: 10px;
    }

    .logos {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0 0 15px;
        text-align: center;
        
        &__logo-img {
            display: inline-block;
            margin: 0 15px 20px 0;

            &:last-of-type {
                margin-right: 0;
            }
                
            &--mw-55 {
                max-width: 55px;
            }

            &--mw-60 {
                max-width: 60px;
            }
            
            &--mw-70 {
                max-width: 70px;
            }

            &--mw-90 {
                max-width: 90px;
            }
    
            &--mw-120 {
                max-width: 120px;
            }
        }
    }

    .country-select {
        background-color: #fff;
        border: 1px solid $macs-navy;
        border-radius: 3px;
        max-width: 100px;
        padding: 10px 35px 10px 10px;
        position: relative;
        text-align: left;
        width: 100%;

        &::after {
            background-image: url('../images/nav-dd-icon.svg');
            background-repeat: no-repeat;
            background-size: 20px;
            content: '';
            height: 12px;
            left: 65px; 
            position: absolute;
            top: 50%;
            transform: translateY(-35%);
            width: 20px;
        }
    }

    .copyright {
        font-size: 0.812rem;
        text-align: center;
    }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
    .footer-nav {
        column-gap: 14px;
        flex-direction: row;
        justify-content: space-between;
        padding: 40px 50px 0;
    }
    
    .nav-group {
        border: none;
        flex: 1 1 0px;
        padding: 0;

        &__radio {
            &:checked ~ .nav-group__label::after {
                content: '';
            }
        }

        &__label {
            &::after {
                content: '';
                margin-left: 13px;
                position: absolute;
                right: 40px;
            }
        }

        &__columns {
            height: auto !important;
        }

        &__footer-link {
            margin-bottom: 6px;

            &:first-of-type {
                margin-top: 10px;
            }
        }
    }

    .affiliates {
        border-top: 1px solid $macs-grey-faded;
        margin: 40px 0 30px;

        &__affiliate-cell {
            &--feefo {
                margin-bottom: 0;
                text-align: left;
                width: 33%;
            }
            &--change-country {
                width: 33%;
            }
            &--accepted-cards {
                width: 33%;
            }
        }
    }

    .logos {
        border-bottom: 1px solid $macs-grey-faded;
        margin: 0 0 40px;

        &__logo-img {
            margin: 0 15px 0 0;
        }
    }

    .copyright {
        text-align: left;
    }
}

@media (min-width: 992px) {    
    .footer-nav {
        padding: 40px 80px 0;
    }
    
    .nav-group {
        
        &__footer-link {
            font-size: 0.875rem;
        }
    }
}

@media (min-width: 1024px) {

}

@media (min-width: 1200px) {

}

@media (min-width: 1920px) {
    .footer-nav {
        max-width: 1740px;
    }
}