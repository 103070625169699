button:focus:not(:focus-visible) {
    outline: none;
}

button:focus-visible {
    outline: 2px solid $macs-yellow;
    outline-offset: 2px;
}

.macs-btn {
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Oswald";
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: .21px;
    padding: 15px 20px;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    white-space: nowrap;

    &:hover {
        transition: all 0.3s ease;
    }

    &--block {
        display: block;
    }

    &--primary {
        background-color: #65b48d;
        border-radius: 0;
        min-width: 248px;
        padding: 22px 20px 25px 20px;

        &:hover {
            background-color: #003643;
        }
    }

    &--secondary {
        background-color: #fff;
        border: 1px solid #fff;
        color: #003643;
        padding: 13px 35px;

        &:hover {
            background-color: transparent;
            color: #fff;
        }
    }

    &--tertiary {
        background-color: $white;
        border: 1px solid $white;
        border-radius: 4px;
        color: $macs-navy;
        font-family: 'Oswald', 'Arial Narrow', 'Helvetica Neue', sans-serif;
        font-size: 1.125rem;
        font-weight: 700;
        padding: 13px 35px;
        transition: all .4s;

        &:hover {
            background-color: transparent;
            color: $white;
        }
    }
}